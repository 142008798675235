const workflows = (apiClient, handleError) => {
    return {
        list: async (includeDisabled) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows?includeDisabled=${includeDisabled ? "true" : "false"}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (workflow) => {
            try {
                const {data} = await apiClient.post("/v1/workflows", workflow);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        detail: async (uuid, steps = true, versions = true) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${uuid}?steps=${steps}&versions=${versions}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        export: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${uuid}/export`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        import: async (json) => {
            try {
                const {data} = await apiClient.post("/v1/workflows/import", json);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        update: async (uuid, workflow) => {
            try {
                const {data} = await apiClient.put(`/v1/workflows/${uuid}`, workflow);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        start: async (uuid, idempotencyId ) => {
            try {
                const {data} = await apiClient.post(`/v1/workflows/${uuid}/start?idempotencyId=${idempotencyId}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        patch: async (uuid, changesObject) => {
            try {
                const {data} = await apiClient.patch(`/v1/workflows/${uuid}`, changesObject);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        flow: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${uuid}/flow`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        parameters: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${uuid}/parameters`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        runAction: async (step) => {
            try {
                const {data} = await apiClient.post("/v1/workflows/runAction", step);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        executions: async (workflowUuid) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${workflowUuid}/executions`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        workflowEntrypoint: async (workflowUuid) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${workflowUuid}/executions/entrypoint`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        workflowEntrypointSave: async (workflowUuid, body) => {
            try {
                const {data} = await apiClient.post(`/v1/workflows/${workflowUuid}/executions/entrypoint`, body);
                return data;
            } catch (error) {
                handleError(error);
            }
        },

        executionCurrentStep: async (workflowUuid, workflowExecutionUuid, uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${workflowUuid}/executions/${workflowExecutionUuid}/steps/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },

        executionCurrentStepSave: async (workflowUuid, workflowExecutionUuid, uuid, body) => {
            try {
                const {data} = await apiClient.post(`/v1/workflows/${workflowUuid}/executions/${workflowExecutionUuid}/steps/${uuid}`, body);
                return data;
            } catch (error) {
                handleError(error);
            }
        },

        executionDetail: async (workflowUuid, uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/workflows/${workflowUuid}/executions/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },

        changeWorkflowVersion: async (workflowUuid, versionUuuid) => {
            try {
                const {data} = await apiClient.post(`v1/workflows/${workflowUuid}/versions/${versionUuuid}/activate`);
                return data;
            } catch (error) {
                handleError(error);
            }
        }
    };
};
export default workflows;