import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Backdrop,
    Box,
    Card,
    CardHeader,
    CircularProgress,
    Divider
} from "@mui/material";

import api from "~/api";
import {useDialog} from "~/providers/dialog";
import {MainPage, LoadingBar, Navbar} from "~/components";

import RenderForm from "./RenderForm";
import RenderApproval from "./RenderApproval";
import {useToast} from "~/providers/dialog";

const WorkflowExecution = () => {
    const {workflowUuid, workflowExecutionUuid, executionStepUuid} = useParams();

    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workflow, setWorkflow] = useState(null);
    const [step, setStep] = useState(null);
    const showDialog = useDialog();
    const showToast = useToast();
    const navigate = useNavigate();

    const [startIdempotencyId] = useState(Math.round(Math.random() * 1000000000))

    useEffect(() => {
        if (workflowUuid) {
            const fetchStep = async () => {
                try {
                    setLoading(true);
                    const workflow = await api.workflows.detail(workflowUuid);
                    setWorkflow(workflow);

                    let data;
                    if (executionStepUuid) {
                        data = await api.workflows.executionCurrentStep(workflowUuid, workflowExecutionUuid, executionStepUuid);
                    } else {
                        data = await api.workflows.workflowEntrypoint(workflowUuid);
                        if (data.type !== "form" && data.type !== "approval") {
                            await api.workflows.start(workflowUuid, startIdempotencyId);
                            showToast("Workflow iniciado com sucesso.");
                            navigate(`/workflows/${workflowUuid}`);
                        }
                    }
                    setStep(data);
                } catch (e) {
                    showDialog("Erro ao carregar informações", e);
                }
                setLoading(false);
            };

            fetchStep().then();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowUuid, executionStepUuid]);

    const handleSave = async (stepData) => {
        setSaveLoading(true);
        try {
            if (executionStepUuid) {
                await api.workflows.executionCurrentStepSave(workflowUuid, workflowExecutionUuid, executionStepUuid, stepData);
            } else {
                await api.workflows.workflowEntrypointSave(workflowUuid, stepData);
            }
            navigate(`/workflows/${workflowUuid}`);
        } catch (e) {
            showDialog("Não foi possível salvar.", e);
        }
        setSaveLoading(false);
    };

    return <MainPage>
        {
            loading ? <LoadingBar/>
                :
                <>
                    <Navbar title={workflow?.title} sx={{zIndex: (theme) => theme.zIndex.drawer + 100}}>
                    </Navbar>
                    <Box sx={{display: "flex", height: "100%", flexDirection: "column"}}>
                        {step &&
                            <Card>
                                <CardHeader title={step.name}/>
                                <Divider/>
                                {step.type === "form" && <RenderForm form={step.form} onSave={handleSave}/>}
                                {step.type === "approval" && <RenderApproval step={step} onSave={handleSave}/>}
                            </Card>
                        }

                    </Box>
                </>
        }
        {saveLoading && <Backdrop sx={{color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1000}} open>
            <CircularProgress color="inherit" size={60}/>
        </Backdrop>}
    </MainPage>;
};
export default WorkflowExecution;